@import "../../scss/variables/variables.scss";
@import "../../scss/mixin/mixin.scss";

.profilePage {
  margin-top: 1rem;
  width: 75rem;
  margin-right: auto;
  margin-left: auto;
  background-color: white;
  border-radius: 0.7rem;
  @include shadow;
  overflow: hidden;
  margin-bottom: 10rem;

  &__header {
    position: relative;
    width: 100%;
    height: 20rem;
    // background-image: linear-gradient($colorsecondry, white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &editButton {
      position: absolute !important;
      top: 1rem !important;
      right: 1rem;
      background-color: $colorbase !important;
      &Icon {
        font-size: 2.5rem !important;
        color: $colorsecondry !important;
      }
    }

    &-Avatar {
      height: 12rem !important;
      width: 12rem !important;
      background-color: $colortertiary !important;
      margin-top: 2rem !important;
      @include shadow;
    }

    h2 {
      margin-top: 1rem;
      @include header(3.5rem);
    }
  }

  &__desc {
    padding: 3rem;
    padding-left: 5rem;
    padding-bottom: 1rem;

    h2 {
      @include header(2.5rem);
    }
    p {
      font-size: 1.5rem;
      width: 50%;
      margin-bottom: 2.5rem;
    }
    button {
      @include button;
      margin-left: 0;
      min-width: 10rem !important;
      margin-bottom: 2.5rem;
    }
  }

  &__followers {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
    border-bottom: 1px solid rgba($colorprimary, $alpha: 0.15);
    border-top: 1px solid rgba($colorprimary, $alpha: 0.15);
    background-color: $colorbase;

    &Box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      h4 {
        font-size: 2rem;
        font-weight: 500;
      }

      h5 {
        font-size: 1.6rem;
        font-weight: 500;
      }
    }
  }

  &__post {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;

    &H3 {
      @include header(2.5rem);
      margin-top: 1rem;
    }
  }
}

@media screen and (max-width: 600px) {
  .profilePage {
    width: 65rem;
  }
}

@media screen and (max-width: 550px) {
  .profilePage {
    width: 58rem;
  }
}

@media screen and (max-width: 465px) {
  .profilePage {
    width: 100vw;

    &__desc {
      margin-left: 5rem;
      padding-left: 0;
    }
  }
}
