@import "../../scss/variables/variables.scss";
@import "../../scss/mixin/mixin.scss";

.profile {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);

  &back {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
    background-color: white;
    height: 54rem;
    border-radius: 0.7rem;
    @include shadow;

    &__cancelButton {
      position: absolute !important;
      z-index: 1000 !important;
      top: -6rem;
      right: -1.5rem;
      cursor: pointer;

      &Icon {
        color: $colorprimary !important;
        font-size: 4rem !important;

        &:hover {
          color: $colortertiary !important;
        }
      }
    }

    &__imgPart {
      width: 50rem;
      height: 100%;
      overflow: hidden;
      object-fit: contain;
      background-color: $colorbase;

      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }

    &__detailPart {
      width: 40rem;
      height: 100%;

      &Header {
        padding-left: 1.5rem;
        height: 7vh;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba($colorprimary, $alpha: 0.15);

        &Right {
          display: flex;
          align-items: center;
          h2 {
            @include header(1.8rem);
            padding-left: 1rem;
          }
        }
      }

      &Desc {
        padding-left: 1.5rem;
        margin-top: 1rem;

        h3 {
          padding-top: 0.5rem;
          font-size: 1.7rem;
          font-weight: 500;
        }

        p {
          font-size: 1.5rem;
          font-weight: 400;
          padding-bottom: 0.5rem;
        }
      }

      &Likes {
        display: flex;
        align-items: center;
        padding-left: 1rem;
        margin-top: 1rem;
        padding-bottom: 1rem;

        .icon {
          font-size: 2.5rem;
          color: gray;
        }
        p {
          font-size: 1.5rem;
        }
      }

      &Timestamp {
        padding-left: 1.5rem;
        padding-bottom: 0.5rem;
        font-size: 1.2rem;
        font-weight: 400;
        color: rgb(172, 172, 172);
        border-bottom: 1px solid rgba($colorprimary, $alpha: 0.15);
      }

      &Comments {
        padding: 1.5rem;
        padding-top: 0;
        h3 {
          font-size: 1.8rem;
        }

        .profileback__detailPartComment {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
}

.profilemobile {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);

  &__back {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &cancelButton {
      position: absolute !important;
      z-index: 1000 !important;
      right: 0rem;
      cursor: pointer;

      &Icon {
        color: $colorprimary !important;
        font-size: 4rem !important;

        &:hover {
          color: $colortertiary !important;
        }
      }
    }

    &deleteButton {
      position: absolute !important;
      z-index: 1000 !important;
      top: 1.5rem !important;
      right: 6rem;
      cursor: pointer;
      height: 4rem !important;
      width: 4rem !important;

      &Icon {
        font-size: 3.5rem !important;
      }
    }
  }
}

// @media screen and (min-width: 701px) {
//   .profile {
//     height: 60rem;
//   }
// }

// @media screen and (max-width: 700px) {
//   .profile {
//     display: flex;
//     flex-direction: column;

//     &__detailPart {
//       width: 100%;
//     }
//   }
// }
