@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "./scss/variables/variables.scss";

* {
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  ::-webkit-scrollbar {
    width: 20px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }
  @supports (-webkit-appearance: none) {
    .os-android & {
      min-height: calc(100vh - 56px);
    }
  }
}

body {
  font-family: "Poppins", sans-serif;
  background-color: $colorbase;
  ::-webkit-scrollbar {
    width: 18px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: lightgray;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }
}

@media screen and (max-width: 1600px) {
  html {
    font-size: 55%;
  }
}

@media screen and (max-width: 750px) {
  html {
    font-size: 50%;
  }
}
