@import "../../scss/mixin/mixin.scss";
@import "../../scss/variables/variables.scss";

.editdesc {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 33rem;
  box-shadow: 0px 0px 100px 41px rgba(0, 0, 0, 0.57);
  -webkit-box-shadow: 0px 0px 100px 41px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 0px 100px 41px rgba(0, 0, 0, 0.57);
}

.removeImg {
  display: flex;
  align-items: center;

  p {
    font-size: 1.5rem;
    font-weight: 500;
    color: gray;
  }
}

.MuiSwitch-track {
  background-color: $colortertiary !important;
}
