@import "./scss/mixin/mixin.scss";

.App {
  overflow-x: hidden;

  &__add {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    background: rgba(0, 0, 0, 0.2);

    &post {
      z-index: 1000;
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translate(-50%);

      &Button {
        position: absolute;
        height: 4rem;
        width: 4rem;
        left: 88%;
        &Icon {
          font-size: 3.5rem !important;
          color: $colortertiary !important;
        }
      }
    }
  }
}
