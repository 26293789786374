@import "../../scss/variables/variables.scss";

.chat__message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 70%;
  font-size: 1.5rem;
  padding: 1.5rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  width: fit-content;
  border-radius: 1.5rem;
  border-top-left-radius: 0;
  background-color: #ffffff;
  margin-bottom: 2rem;
  margin-left: 2rem;
  margin-top: 2rem;
}

.reciever {
  margin-left: auto;
  background-color: $colortertiary;
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 0;
  margin-right: 1rem;
  align-items: flex-end;
  color: white;
}
