@import "../../scss/variables/variables.scss";
@import "../../scss/mixin/mixin.scss";

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @include shadow;
  position: fixed !important;
  top: 0;
  z-index: 1000;
  background-color: white;
  height: 5.4rem;
  overflow: hidden;

  &web {
    width: 110rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__logo {
      cursor: pointer;
      margin-top: 0.5rem;
      width: 19.5rem;
      img {
        height: 4rem;
      }
    }

    &__search {
      display: flex;
      justify-content: center;
      &Box {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        background-color: white;
        border-radius: 10rem;
        width: 30rem;
        height: 3.5rem;
        @include shadow;

        &Input {
          font-size: 1.5rem;
          width: 20rem;
          height: 1.5rem;
          padding: 1rem;
          border-width: 0;
          outline-width: 0;
          background-color: white;
          font-family: "poppins", "sans-serif";
        }
        &Icon {
          color: $colorsecondry;
          font-size: 2.5rem !important;
        }

        h4 {
          font-size: 1.9rem;
          font-weight: 600;
          margin-left: 2rem;
          margin-top: 1rem;
          color: $colorprimary;
        }
        &Result {
          flex: 0.33;
          position: fixed;
          top: 7rem;
          z-index: 10000;
          max-height: 30rem;
          width: 35rem;
          left: 50%;
          transform: translate(-50%);
          background-color: white;
          border-radius: 0.6rem;
          @include shadow;
          display: flex;
          flex-direction: column;
          overflow: hidden;

          &Container {
            overflow: scroll;
            &Options {
              display: flex;
              align-items: center;
              padding: 0.5rem;
              margin-top: 1rem;
              margin-left: 1rem;
              padding-left: 1rem;
              padding-right: 1rem;
              cursor: pointer;
              border-radius: 1rem;

              &:hover {
                background-color: rgb(226, 226, 226);
              }
              p {
                font-size: 1.5rem;
                margin-left: 1rem;
              }
            }
          }
        }
      }
    }
    &__icons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 19.5rem;

      .headerweb__icon {
        color: $colorsecondry;
        font-size: 3rem !important;
        padding: 1rem;
      }
    }
    &__avatar {
      height: 4rem !important;
      width: 4rem !important;
    }
  }
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  @include shadow;
  position: fixed;
  top: 0;
  z-index: 1000;
  background-color: white;
  overflow: hidden;

  &mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &__logoutButton {
      margin-left: 2rem !important;
      height: 3.8rem;
      width: 3.8rem;
      background-color: $colorsecondry !important;
      &Icon {
        color: $colorbase;
        font-size: 2.5rem !important;
        padding-left: 0.1rem;
        padding-top: 0.1rem;
      }
    }

    &__logo {
      cursor: pointer;
      margin-top: 0.5rem;
      width: 16rem;
      img {
        width: 16rem;
      }
    }

    &__search {
      &Box {
        display: flex;
        align-items: center;
        margin-right: 2rem;

        &Result {
          position: fixed;
          top: 6.5rem;
          z-index: 10000;
          max-height: 30rem;
          width: 35rem;
          left: 50%;
          transform: translate(-50%);
          background-color: white;
          border-radius: 0.6rem;
          @include shadow;
          display: flex;
          flex-direction: column;
          overflow: hidden;

          &Search {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $colorbase;
            width: 34.5rem;
            padding: 0.5rem;
            padding-bottom: 1rem;
            height: 3.5rem;

            &Input {
              font-size: 1.5rem;
              width: 31.5rem !important;
              height: 1.5rem;
              padding: 1rem;
              border-radius: 1.5rem;
              border-width: 0;
              outline-width: 0;
              background-color: white;
              font-family: "poppins", "sans-serif";
            }
          }

          h4 {
            font-size: 1.9rem;
            font-weight: 600;
            margin-left: 2rem;
            margin-top: 1rem;
            color: $colorprimary;
          }

          &Container {
            overflow: scroll;
            &Options {
              display: flex;
              align-items: center;
              padding: 0.5rem;
              margin-top: 1rem;
              padding-left: 2rem;
              padding-right: 1rem;
              cursor: pointer;
              &:hover {
                background-color: rgb(226, 226, 226);
              }
              p {
                font-size: 1.5rem;
                margin-left: 1rem;
              }
            }
          }
        }
        &Button {
          height: 3.8rem;
          width: 3.8rem;
          background-color: $colorsecondry !important;
          @include shadow;

          &Icon {
            color: $colorbase;
            font-size: 2.5rem !important;
            padding-left: 0.1rem;
            padding-top: 0.1rem;
          }
        }
      }
    }

    &__avatar {
      height: 4rem !important;
      width: 4rem !important;
    }
  }
}

@media screen and (max-width: 1073px) {
  .header {
    &web {
      width: 80rem;
    }
  }
}
