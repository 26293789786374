@import "../../scss/mixin/mixin.scss";
@import "../../scss/variables/variables.scss";

.sidebar {
  width: 37%;
  background-color: white;
  border-right: 1px solid rgba($colorprimary, $alpha: 0.2);

  &__header {
    height: 5.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid lightgray;

    &H3 {
      margin-left: 2rem;
      h3 {
        @include header(2.5rem);
        text-align: left;
      }
    }
  }

  &__conversations {
    height: calc(100vh - 19rem) !important;
  }
}

.sidebar {
  position: relative;
  &__searchBox {
    display: flex;
    align-items: center;
    margin-right: 2rem;

    &Result {
      position: absolute;
      top: 9%;
      z-index: 100;
      max-height: 30rem;
      width: 90%;
      left: 50%;
      transform: translate(-50%);
      background-color: white;
      border-radius: 0.6rem;
      -webkit-box-shadow: 0px 0px 10px 0px rgba(51, 66, 87, 0.4);
      -moz-box-shadow: 0px 0px 10px 0px rgba(51, 66, 87, 0.4);
      box-shadow: 0px 0px 10px 0px rgba(51, 66, 87, 0.4);
      display: flex;
      flex-direction: column;
      overflow: hidden;

      &Search {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $colorbase;
        width: 98%;
        padding: 0.5rem;
        padding-bottom: 1rem;
        height: 3.5rem;

        &Input {
          font-size: 1.5rem;
          width: 97% !important;
          height: 1.5rem;
          padding: 1rem;
          border-radius: 1.5rem;
          border-width: 0;
          outline-width: 0;
          background-color: white;
          font-family: "poppins", "sans-serif";
        }
      }

      h4 {
        font-size: 1.9rem;
        font-weight: 600;
        margin-left: 2rem;
        margin-top: 1rem;
        color: $colorprimary;
      }

      &Container {
        margin-top: 1rem;
        overflow: scroll;
        &Options {
          display: flex;
          align-items: center;
          padding: 0.5rem;
          margin-bottom: 1rem;
          padding-left: 2rem;
          padding-right: 1rem;
          cursor: pointer;
          &:hover {
            background-color: rgb(226, 226, 226);
          }
          p {
            font-size: 1.5rem;
            margin-left: 1rem;
          }
        }
      }
    }
    &Button {
      height: 3.8rem;
      width: 3.8rem;

      &Icon {
        color: $colorprimary;
        font-size: 2.5rem !important;
        padding-left: 0.1rem;
        padding-top: 0.1rem;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .sidebar {
    width: 100%;
  }
}
